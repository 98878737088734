
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Montserrat;
    font-weight: 400;
    src: url(fonts/montserrat/files/montserrat-latin-400-normal.woff2) format("woff2");
  }
  
  /* font-medium */
  @font-face {
    font-family: Montserrat;
    font-weight: 500;
    src: url(fonts/montserrat/files/montserrat-latin-500-normal.woff2) format("woff2");
  }

  /* font-bold */
  @font-face {
    font-family: Montserrat;
    font-weight: 700;
    src: url(fonts/montserrat/files/montserrat-latin-700-normal.woff2) format("woff2");
  }  
  
  /* font-extrabold */
  @font-face {
    font-family: Montserrat;
    font-weight: 800;
    src: url(fonts/montserrat/files/montserrat-latin-800-normal.woff2) format("woff2");
  }  
}

@layer components {
  /* svg's brought in via <img>-tag cannot be styled, but color can be applied via filter 
  Correct color found via: https://codepen.io/sosuke/pen/Pjoqqp */
  .svg-purple {
    filter: invert(7%) sepia(39%) saturate(7472%) hue-rotate(264deg) brightness(70%) contrast(112%); 
  }
  
  .claim-gradient {
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(70deg, #ade1f8 0%, #e9defe 20%, #ffffff 25%, #ebdefa 50%, #b0eefd 77%, #f0eaee 80%, #b0eefd 84%, #9e7afe 95%);
  }
}
