*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url("montserrat-latin-400-normal.aca40b88.woff2") format("woff2");
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url("montserrat-latin-500-normal.bac7f098.woff2") format("woff2");
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url("montserrat-latin-700-normal.430731b9.woff2") format("woff2");
}

@font-face {
  font-family: Montserrat;
  font-weight: 800;
  src: url("montserrat-latin-800-normal.e7ecdf1f.woff2") format("woff2");
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.svg-purple {
  filter: invert(7%) sepia(39%) saturate(7472%) hue-rotate(264deg) brightness(70%) contrast(112%);
}

.claim-gradient {
  color: #0000;
  -webkit-background-clip: text;
  background-image: linear-gradient(70deg, #ade1f8 0%, #e9defe 20%, #fff 25%, #ebdefa 50%, #b0eefd 77%, #f0eaee 80%, #b0eefd 84%, #9e7afe 95%);
  -webkit-background-clip: text;
  background-clip: text;
}

.m-auto {
  margin: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mr-\[5vw\] {
  margin-right: 5vw;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-0 {
  margin-bottom: 0;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.contents {
  display: contents;
}

.h-full {
  height: 100%;
}

.h-10 {
  height: 2.5rem;
}

.h-8 {
  height: 2rem;
}

.h-7 {
  height: 1.75rem;
}

.h-16 {
  height: 4rem;
}

.w-2\/5 {
  width: 40%;
}

.w-1 {
  width: .25rem;
}

.w-full {
  width: 100%;
}

.w-10 {
  width: 2.5rem;
}

.min-w-min {
  min-width: min-content;
}

.min-w-\[4px\] {
  min-width: 4px;
}

.max-w-max {
  max-width: max-content;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-grow {
  flex-grow: 1;
}

.list-disc {
  list-style-type: disc;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse) );
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.self-center {
  align-self: center;
}

.rounded-full {
  border-radius: 9999px;
}

.bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(19 0 45 / var(--tw-bg-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-pauli {
  background-image: url("pauli.9397edbe.jpg");
}

.bg-\[length\:440px\] {
  background-size: 440px;
}

.bg-\[left_30vw_top_196px\] {
  background-position: 30vw 196px;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-7 {
  padding: 1.75rem;
}

.p-\[\.1875rem\] {
  padding: .1875rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-1 {
  padding: .25rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-0 {
  padding-bottom: 0;
}

.font-sans {
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[20vw\] {
  font-size: 20vw;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-medium {
  font-weight: 500;
}

.font-extrabold {
  font-weight: 800;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.leading-\[16vw\] {
  line-height: 16vw;
}

.leading-\[1\.125rem\] {
  line-height: 1.125rem;
}

.leading-4 {
  line-height: 1rem;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-light-purple {
  --tw-text-opacity: 1;
  color: rgb(97 64 177 / var(--tw-text-opacity) );
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (min-width: 640px) {
  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:bg-\[left_30vw_top_120px\] {
    background-position: 30vw 120px;
  }

  .sm\:text-\[6\.5rem\] {
    font-size: 6.5rem;
  }

  .sm\:leading-\[4\.75rem\] {
    line-height: 4.75rem;
  }
}

@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:-top-10 {
    top: -2.5rem;
  }

  .md\:right-12 {
    right: 3rem;
  }

  .md\:bottom-12 {
    bottom: 3rem;
  }

  .md\:m-auto {
    margin: auto;
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .md\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-1\/3 {
    height: 33.3333%;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:min-h-\[100vh\] {
    min-height: 100vh;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-min {
    width: min-content;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:self-end {
    align-self: flex-end;
  }

  .md\:bg-pauli-large {
    background-image: url("pauli_large.c2e1b2d9.jpg");
  }

  .md\:bg-\[length\:80vh\] {
    background-size: 80vh;
  }

  .md\:bg-\[right_20\%_top_54px\] {
    background-position: right 20% top 54px;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pr-28 {
    padding-right: 7rem;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 1024px) {
  .lg\:bg-\[right_30\%_top_54px\] {
    background-position: right 30% top 54px;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:bg-\[right_40\%_top_54px\] {
    background-position: right 40% top 54px;
  }

  .xl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
}

/*# sourceMappingURL=index.4992dc08.css.map */
